import { Inject, Injectable } from '@angular/core';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { MetaTags } from '../interfaces';
import { DEFAULT_META_TAGS } from '../const';
import { DOCUMENT } from '@angular/common';
import { isProdDomain } from 'meta/helpers';

const DEFAULT_PAGE_TITLE = 'Цифровые рабочие тетради к учебникам «Просвещения»';
const DEFAULT_DESCRIPTION = 'Библиотека интерактивных упражнений с автопроверкой. Полностью соответствует учебникам федерального перечня. Очень просто найти задания к параграфу учебника.';

// mapping regular meta tags to og tags
const OG_TAGS_MAPPING = {
  title: 'og:title',
  description: 'og:description',
};

@Injectable({ providedIn: 'root' })
export class MetaService {
  private relCanonicalElement: HTMLLinkElement | undefined;

  constructor(private title: Title, private meta: Meta, @Inject(DOCUMENT) private document: Document) {
    this.resetToDefaults();
  }

  setTitle(title: string): void {
    this.title.setTitle(title);

    this.updateMappedOgTag({ name: 'title', content: title });
  }

  update(tags: MetaDefinition[] = []): void {
    tags.forEach(tag => {
      this.meta.updateTag(tag);
      this.updateMappedOgTag(tag);
    });

    this.meta.updateTag({ property: 'og:url', content: this.getCurrentUrl() });
    this.ensureRelCanonicalExists();
  }

  addTag(meta: MetaDefinition, forceCreation?: boolean): ReturnType<Meta['addTag']> {
    return this.meta.addTag(meta, forceCreation);
  }

  addTags(meta: MetaDefinition[], forceCreation?: boolean): ReturnType<Meta['addTags']> {
    return this.meta.addTags(meta, forceCreation);
  }

  removeTagsBy<KeyType extends keyof MetaDefinition>(key: KeyType, metaDefs: MetaDefinition[]): void {
    this.remove(metaDefs.map(metaDef => `${key}="${metaDef[key]}"`));
  }

  /**
   * example of input ['property="og:type"'] for tag <meta property="og:type" content="website">
   * @param tagsSelectors array of selectors for meta tags
   */
  remove(tagsSelectors: string[]): void {
    tagsSelectors.forEach(tagSelector => this.meta.removeTag(tagSelector));
  }

  /**
   * Meta service uses pattern `meta[${attrSelector}]`
   */
  createMetaQuerySelector(tagSelectors: [name: string, value: string][]): string {
    return tagSelectors.reduce((result, [currentName, currentValue]) => {
      if (result !== '') {
        result += '][';
      }
      return `${result}${currentName}="${currentValue}"`;
    }, '');
  }

  setTags(tags: MetaTags): void {
    this.setTitle(tags.title);
    this.update([{ name: 'description', content: tags.description ?? '' }, ...(tags.openGraph ?? [])]);
  }

  removeRelCanonical(): void {
    if (!this.relCanonicalElement) {
      return;
    }

    this.relCanonicalElement.remove();
    this.relCanonicalElement = undefined;
  }

  setIndexable(allowIndex: boolean): void {
    this.meta.updateTag({ name: 'robots', content: allowIndex && isProdDomain() ? '' : 'noindex, nofollow' });
  }

  setDefaultImage(): void {
    this.update([{ property: 'og:image', content: window.location.origin + '/assets/og-image.png' }]);
  }

  resetToDefaults(): void {
    this.setTags({ title: DEFAULT_PAGE_TITLE, description: DEFAULT_DESCRIPTION });
    this.update(DEFAULT_META_TAGS);
  }

  setBodySchema(schemaOrgPageType?: string): void {
    const body = this.document.body;
    if (schemaOrgPageType) {
      body.setAttribute('itemscope', '');
      body.setAttribute('itemtype', `https://schema.org/${schemaOrgPageType}`);
    } else {
      body.removeAttribute('itemscope');
      body.removeAttribute('itemtype');
    }
  }

  private updateMappedOgTag({ name, content }: MetaDefinition): void {
    // @ts-ignore
    const ogTagName = OG_TAGS_MAPPING[name!];

    if (!ogTagName) {
      return;
    }

    this.meta.updateTag({ property: ogTagName, content: content || '' });
  }

  private ensureRelCanonicalExists(): void {
    const relCanonicalElement = this.getRelCanonicalElement();
    const href = this.getCurrentUrl();

    relCanonicalElement.setAttribute('href', href);
  }

  private getRelCanonicalElement(): HTMLLinkElement {
    if (this.relCanonicalElement) {
      return this.relCanonicalElement;
    }

    let relCanonicalElement = this.document.head.querySelector('link[rel="canonical"]') as HTMLLinkElement | null;

    if (!relCanonicalElement) {
      relCanonicalElement = this.document.createElement('link');
      relCanonicalElement.setAttribute('rel', 'canonical');
      const insertAfterNode = this.meta.getTag('name="yandex-verification"');
      if (insertAfterNode) {
        insertAfterNode.after(relCanonicalElement);
      } else {
        this.document.head.appendChild(relCanonicalElement);
      }
    }

    this.relCanonicalElement = relCanonicalElement;

    return this.relCanonicalElement;
  }

  private getCurrentUrl(): string {
    return window.location.origin + window.location.pathname;
  }
}
